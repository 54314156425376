#loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 98vw;
  height: 100vh !important;
  background-image: url("https://s3.ap-southeast-1.amazonaws.com/media.nacjewellers.com/resources/home_page/NAC+nemonic+loader+gif+low+res.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.overall-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh !important;
  background-size: 150% 150%;
  position: fixed;
  top: 0;
  background-color: #ffffff !important;
  z-index: 99999999;
}
